.schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  flex-grow: 1;
}

.schedule h1 {
  padding: 10px 0 20px 0;
  text-align: center;
}
