.home {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  max-width: 700px;
}

.home header {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.home h1 {
  padding: 10px 0 20px 0;
  text-align: center;
}

.home__our-photo {
  width: 100%;
  max-width: 500px;
  margin: 40px 0;
  border: 1px solid white;
  border-radius: 10px;
}

.home__countdown {
  padding-top: 20px;
  text-align: center;
}
