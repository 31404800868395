.page {
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.8);
  background: url("/us_compressed.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

h1, h2, h3, h4, h5 {
  width: 100%;
}

h1, h2 {
  text-align: center;
  margin: 30px 0 20px 0;
}

h3, h4, h5 {
  width: 100%;
  margin: 20px 0 10px 0;
}

p {
  margin: 5px 0 10px 0;
}