.map {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  flex-grow: 1;
}

.map--loading {
  display: none;
}

.map--loaded {
  width: 80vw;
  height: 80vh;
}