.questionnaire {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  flex-grow: 1;
}

.questionnaire__submit-button {
  float: right;
  margin-top: 20px;
}

.questionnaire__paper {
  padding: 20px;
  max-width: 800px;
}

.attendees-table {
  margin: 20px 0;
}

.attendees-table__icon {
  vertical-align: inherit;
}

.attendees-table__delete-icon {
  vertical-align: inherit;
  cursor: pointer;
}

.questionnaire__attendees-list {
  max-width: 80vw;
}

.questionnaire__paragraph {
  padding-top: 24px;
}

.questionnaire__checkboxes {
  padding-top: 20px;
}