.gifts {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.gifts__poem p {
  margin: 10px;
}

.gifts__thanks {
  text-align: right;
}
