.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  flex-grow: 1;
  max-width: 700px;
}

.info h1 {
  padding: 10px 0 20px 0;
  text-align: center;
}
