.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.login__paper {
  padding: 20px;
  max-width: 500px;
}

.login__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.login__submit-button {
  float: right;
}

.login__logout-button {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}